module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":["/"],"height":["/"],"color":"#337AFF"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mobility Aids New Zealand","short_name":"Mobility Aids","start_url":"/","background_color":"white","theme_color":"#337AFF","icon":"src/images/mobility_aids_favicon.svg","display":"standalone"},
    }]
