// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-products-tsx": () => import("./../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../src/templates/generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-products-tsx": () => import("./../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */)
}

